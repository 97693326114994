<template>
  <div>
    <b-form v-if="item.uuid" @submit.prevent="submitUpdate">
      <div class="form-row">
        <div class="col-md-8">
          <b-card v-if="course" header="Disciplinas" class="mb-1">
            <b-card-text>
              <ul
                class="list-disciplines p-0 mb-0 mt-1"
                v-if="course.disciplines.length"
              >
                <li
                  class="form-row pb-1"
                  v-for="(discipline, index) in disciplines"
                  :id="`discipline-id-${index}`"
                  :key="`discipline-key-${index}`"
                >
                  <div class="col-md-8 text-left">
                    {{ discipline.discipline.title }}
                  </div>

                  <div class="col-md-2">
                    <b-badge variant="danger">
                      <div
                        class="d-flex justify-content-center align-items-center"
                        v-if="discipline.dipe"
                        style="column-gap: 5px"
                      >
                        <svg
                          v-b-tooltip.hover.top="
                            'Disciplina inserida pós edital'
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="15"
                          height="15"
                          color="#fff"
                          fill="none"
                        >
                          <path
                            d="M5.32171 9.6829C7.73539 5.41196 8.94222 3.27648 10.5983 2.72678C11.5093 2.42437 12.4907 2.42437 13.4017 2.72678C15.0578 3.27648 16.2646 5.41196 18.6783 9.6829C21.092 13.9538 22.2988 16.0893 21.9368 17.8293C21.7376 18.7866 21.2469 19.6548 20.535 20.3097C19.241 21.5 16.8274 21.5 12 21.5C7.17265 21.5 4.75897 21.5 3.46496 20.3097C2.75308 19.6548 2.26239 18.7866 2.06322 17.8293C1.70119 16.0893 2.90803 13.9538 5.32171 9.6829Z"
                            stroke="currentColor"
                            stroke-width="2"
                          />
                          <path
                            d="M11.992 16H12.001"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 13L12 8.99997"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <span>
                          {{ discipline.dipe ? "DIPE" : "" }}
                        </span>
                      </div>
                    </b-badge>
                  </div>

                  <div class="col-md-2 text-center">
                    <b-badge variant="light-primary">
                      {{ discipline.workload }}h
                    </b-badge>
                  </div>
                </li>
              </ul>

              <p class="mb-0 mt-1" v-else>
                Nenhuma disciplina vinculado ao curso até o momento.
              </p>
            </b-card-text>
          </b-card>

          <div class="form-row">
            <div class="col-md-12">
              <b-tabs pills>
                <b-tab active>
                  <template #title>
                    <span class="d-none d-sm-inline">Informações</span>
                  </template>

                  <b-card header="Informações básicas" class="mb-1">
                    <b-card-text>
                      <div class="form-row">
                        <div class="col-md-9">
                          <b-form-group>
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Curso
                            </label>
                            <v-select
                              label="title"
                              item-text="title"
                              item-value="code"
                              v-model="item.course"
                              placeholder="Digite o título"
                              :options="optionsCourses"
                              @search="fetchCourse"
                              :class="{ 'is-invalid': $v.item.course.$error }"
                              @input="courseSelected"
                            >
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </b-form-group>
                        </div>

                        <div class="col-md-3">
                          <b-form-group>
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Modalidade
                            </label>
                            <v-select
                              :searchable="false"
                              label="title"
                              item-text="title"
                              item-value="code"
                              v-model="item.modality"
                              placeholder="Digite o título"
                              :options="optionsModalities"
                              :class="{ 'is-invalid': $v.item.modality.$error }"
                            >
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </b-form-group>
                        </div>

                        <div class="col-md-3">
                          <b-form-group>
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Código
                            </label>
                            <input
                              disabled
                              v-model="item.name"
                              class="form-control"
                              type="text"
                              :class="{ 'is-invalid': $v.item.name.$error }"
                            />
                          </b-form-group>
                        </div>

                        <div class="col-md-3">
                          <b-form-group>
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Turno
                            </label>
                            <v-select
                              :searchable="false"
                              label="title"
                              item-text="title"
                              item-value="code"
                              v-model="item.shift"
                              placeholder="Digite o título"
                              :options="optionsShifts"
                              :class="{ 'is-invalid': $v.item.shift.$error }"
                            >
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </b-form-group>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Início <i class="bi bi-clock"></i>
                            </label>
                            <input
                              placeholder="HH:mm"
                              v-model="item.start_time"
                              class="form-control"
                              type="tel"
                              v-mask="['##:##']"
                              :class="{
                                'is-invalid': $v.item.start_time.$error,
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Término <i class="bi bi-clock"></i>
                            </label>
                            <input
                              placeholder="HH:mm"
                              v-model="item.end_time"
                              class="form-control"
                              type="tel"
                              v-mask="['##:##']"
                              :class="{ 'is-invalid': $v.item.end_time.$error }"
                            />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Intervalo <i class="bi bi-clock"></i>
                            </label>
                            <input
                              placeholder="HH:mm"
                              v-model="item.interval"
                              class="form-control"
                              type="tel"
                              v-mask="['##:##']"
                              :class="{ 'is-invalid': $v.item.interval.$error }"
                            />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Acesso até
                              <i
                                class="bi bi-question-circle"
                                v-b-tooltip.hover.top="
                                  'Defina até quando aluno poderá ter acesso as aulas. Exceto para alunos passaporte.'
                                "
                              ></i>
                            </label>
                            <flat-pickr
                              v-model="item.date_end"
                              class="form-control"
                              :config="{
                                altInput: true,
                                dateFormat: 'Y-m-d',
                                altFormat: 'd/m/Y',
                                locale: 'pt',
                              }"
                            />
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Está confirmada?
                            </label>
                            <v-select
                              :searchable="false"
                              label="title"
                              item-text="title"
                              item-value="code"
                              v-model="item.confirmed"
                              placeholder="Digite o título"
                              :options="[
                                { code: 1, title: 'Sim' },
                                { code: 0, title: 'Não' },
                              ]"
                            >
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </div>
                        </div>

                        <div
                          v-if="item.modality.title === 'Presencial'"
                          class="col-md-6"
                        >
                          <b-form-group>
                            <label>
                              <i class="text-danger bi bi-record-circle"></i>
                              Unidade
                            </label>
                            <v-select
                              label="title"
                              :searchable="false"
                              item-text="title"
                              item-value="code"
                              v-model="item.unit"
                              placeholder="Digite o título"
                              :options="optionsUnits"
                              :class="{ 'is-invalid': $v.item.unit.$error }"
                            >
                              <span slot="no-options">
                                Nenhum registro encontrado
                              </span>
                            </v-select>
                          </b-form-group>
                        </div>
                      </div>
                    </b-card-text>
                    <b-card-footer class="p-0 pt-2 footer-class">
                      <div class="form-row justify-content-between">
                        <div class="col-md-6 col-7">
                          <b-form-group class="mb-0">
                            <b-form-checkbox
                              v-model="item.display_panel"
                              :value="1"
                              class="custom-control-primary"
                            >
                              Exibir no painel de vendas
                            </b-form-checkbox>
                          </b-form-group>
                        </div>

                        <div class="col-md-3 col-5">
                          <i
                            style="
                              float: left;
                              line-height: 3.1;
                              margin-right: 10px;
                            "
                            class="text-danger bi bi-record-circle"
                          ></i>
                          <b-form-group class="mb-0">
                            <v-select
                              :searchable="false"
                              label="title"
                              v-model="item.status"
                              :class="{ 'is-invalid': $v.item.status.$error }"
                              :options="[
                                { title: 'Ativo', code: 1 },
                                { title: 'Inativo', code: 0 },
                              ]"
                            />
                          </b-form-group>
                        </div>
                      </div>
                    </b-card-footer>
                  </b-card>
                </b-tab>

                <b-tab v-if="item.modality.title === 'Live'">
                  <template #title>
                    <span class="d-none d-sm-inline">Live</span>
                  </template>
                  <b-card
                    class="mb-1 card-header-custom-actions"
                    header-tag="header"
                  >
                    <template #header>
                      <h4
                        class="mb-0"
                        style="
                          line-height: 1.5;
                          font-weight: 500;
                          font-size: 1.1rem;
                          color: #6e6b7b;
                        "
                      >
                        <i class="text-danger bi bi-record-circle"></i>
                        Link da Live
                      </h4>
                    </template>
                    <b-form-group class="mb-0">
                      <input
                        class="form-control"
                        v-model="item.link"
                        placeholder="https://...."
                        :class="{ 'is-invalid': $v.item.link.$error }"
                      />
                    </b-form-group>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <span class="d-none d-sm-inline">Parcelas</span>
                  </template>
                  <b-card
                    class="mb-1 card-header-custom-actions"
                    header-tag="header"
                  >
                    <template #header>
                      <h4 class="mb-0">Parcelas</h4>
                    </template>

                    <b-card-text>
                      <div class="form-row form-row-header-repeat">
                        <div class="col-md-1 text-center d-none d-sm-block">
                          <label for="">Nº</label>
                        </div>

                        <div class="col-md-2">
                          <div class="form-group">
                            <label for="">Valor</label>
                          </div>
                        </div>

                        <div
                          class="col-md-2 text-center d-none d-sm-block"
                          v-b-tooltip.hover.top="'Parcelas x Valor'"
                        >
                          <label for="">Total</label>
                        </div>

                        <div
                          v-for="(itemFPayment, index) in payment_forms"
                          :key="index"
                          :id="index"
                          class="col-md-1 col-1 text-center"
                        >
                          <div
                            class="form-group"
                            v-b-tooltip.hover.top="itemFPayment.title"
                          >
                            <label for="">
                              <i
                                style="font-size: 1.7rem !important"
                                :class="itemFPayment.icon"
                              ></i>
                            </label>
                          </div>
                        </div>

                        <p v-if="!payment_forms.length">
                          Nenhuma parcela cadastrada até o momento.
                        </p>
                      </div>

                      <div
                        class="form-row bg"
                        v-for="(item, index) in course.installments"
                        :id="`installment-id-${index}`"
                        :key="`installment-key-${index}`"
                      >
                        <div
                          class="col-2 col-md-1 text-center d-none d-sm-block"
                        >
                          <div
                            class="form-group mb-0"
                            style="line-height: 3; margin-bottom: 0px"
                          >
                            <strong>{{ index + 1 }}x</strong>
                          </div>
                        </div>

                        <div class="col-md-2 col-4">
                          <div class="form-group mb-0" style="margin-top: 7px">
                            {{ item.value | toCurrency }}
                          </div>
                        </div>

                        <div
                          class="col-md-2 text-center d-none d-sm-block"
                          v-b-tooltip.hover.top="'Parcelas x Valor'"
                        >
                          <div
                            class="form-group mb-0"
                            style="line-height: 4; margin-bottom: 0px"
                          >
                            {{ ((index + 1) * item.value) | toCurrency }}
                          </div>
                        </div>

                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Dinheiro'"
                          >
                            <i
                              v-if="item.money"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>

                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Cartão de débito'"
                          >
                            <i
                              v-if="item.card_debit"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>

                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Cartão de crédito'"
                          >
                            <i
                              v-if="item.card_credit"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>

                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Pix'"
                          >
                            <i
                              v-if="item.pix"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>

                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Cheque'"
                          >
                            <i
                              v-if="item.check"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>
                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Carta de Crédito'"
                          >
                            <i
                              v-if="item.letter"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>
                        <div class="col-md-1 col-2 text-center">
                          <div
                            class="form-group mb-0"
                            v-b-tooltip.hover.top="'Boleto'"
                          >
                            <i
                              v-if="item.billet"
                              class="bi bi-check text-success"
                              style="font-size: 1.6rem"
                            ></i>
                          </div>
                        </div>
                      </div>
                    </b-card-text>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <span class="d-none d-sm-inline">Matrículas</span>
                  </template>

                  <b-card
                    class="mb-1 card-header-custom-actions"
                    header-tag="header"
                    header="Transferência"
                  >
                    <div class="form-row" style="align-items: center">
                      <div class="col-md-11">
                        <div class="form-group mb-0">
                          <v-select
                            label="title"
                            item-text="title"
                            item-value="code"
                            placeholder="Turma"
                            v-model="formTransfer.classroom"
                            :options="optionsClassrooms"
                            @search="fetchClassrooms"
                            :class="{
                              'is-invalid': $v.formTransfer.classroom.$error,
                            }"
                          >
                            <span slot="no-options">
                              Nenhum registro encontrado
                            </span>
                          </v-select>
                        </div>
                      </div>
                      <div class="col-md-1">
                        <button
                          :disabled="
                            !formTransfer.orders.length || submittedTransfer
                              ? true
                              : false
                          "
                          @click="confirmTransfer"
                          type="button"
                          class="btn btn-primary btn-block"
                        >
                          <i
                            v-if="!submittedTransfer"
                            class="bi bi-arrow-left-right"
                          ></i>
                          <b-spinner v-else small variant="light" />
                        </button>
                      </div>
                    </div>

                    <p
                      v-if="
                        $v.formTransfer.orders.$error &&
                        !formTransfer.orders.length
                      "
                      class="mt-2 alert alert-danger p-1"
                    >
                      <i class="bi bi-exclamation-circle mr-1"></i>
                      Selecione 1 ou mais matrículas abaixo.
                    </p>

                    <b-button
                      icon="PrinterIcon"
                      variant="primary"
                      class="my-2"
                      @click="printTable"
                      >Imprimir</b-button
                    >

                    <div ref="printSection">
                      <b-table
                        sticky-header
                        class="position-relative mt-1"
                        :items="item.customers"
                        responsive
                        :fields="fieldsCustomers"
                        primary-key="id"
                        show-empty
                        empty-text="Nenhum registro encontrado"
                      >
                        <template #head(select)="{ item }">
                          <b-form-checkbox
                            class="custom-control-orders custom-control-success float-left"
                            name="check-button"
                            inline
                            :value="1"
                            @change="toggleSelectAll"
                            v-model="selectAll"
                          />
                        </template>

                        <template #cell(order_id)="{ item }">
                          <b-link
                            class="alert-link"
                            :to="{
                              name: 'transactions-orders-preview',
                              params: { uuid: item.order_uuid },
                            }"
                          >
                            {{ item.order_id }}
                          </b-link>
                        </template>

                        <template #cell(phone)="{ item }">
                          {{ item.customer_cellphone || item.customer_phone }}
                        </template>

                        <template #cell(select)="row">
                          <b-form-checkbox
                            class="custom-control-orders custom-control-success"
                            style="display: inline"
                            name="check-button"
                            inline
                            :value="1"
                            v-model="row.item.selected"
                            @change="selectOrder(row.item.order_uuid)"
                          />
                        </template>

                        <template #cell(customer_name)="{ item }">
                          <b-link
                            class="alert-link"
                            :to="{
                              name: 'customers-edit',
                              params: { uuid: item.customer_uuid },
                            }"
                          >
                            {{ item.customer_name }}
                          </b-link>
                        </template>

                        <template #cell(order_date)="{ item }">
                          {{ item.order_date | datePT(false) }}
                        </template>
                      </b-table>
                    </div>
                  </b-card>
                </b-tab>

                <b-tab>
                  <template #title>
                    <span class="d-none d-sm-inline">Transferências</span>
                  </template>

                  <b-card
                    class="mb-1 card-header-custom-actions"
                    header-tag="header"
                    header="Histórico de transferências"
                  >
                    <b-table
                      sticky-header
                      class="position-relative"
                      :items="item.transfers"
                      responsive
                      :fields="fieldsTransfers"
                      primary-key="id"
                      show-empty
                      empty-text="Nenhum registro encontrado"
                    >
                      <template #head(status)="{ item }">
                        <i class="bi bi-arrow-down-up"></i>
                      </template>

                      <template #cell(status)="data">
                        <b-badge
                          :variant="
                            item.name === data.item.classroom_from_name
                              ? 'danger'
                              : 'success'
                          "
                        >
                          <i
                            class="bi"
                            :class="
                              item.name === data.item.classroom_from_name
                                ? 'bi-arrow-up'
                                : 'bi-arrow-down'
                            "
                          ></i>
                        </b-badge>
                      </template>

                      <template #cell(customer_name)="{ item }">
                        {{ firstName(item.customer_name) }}
                      </template>

                      <template #cell(user_name)="{ item }">
                        <i
                          class="bi bi-person-fill"
                          v-b-tooltip.hover.top="`Usuário: ${item.user_name}`"
                        ></i>
                      </template>

                      <template #cell(order_id)="{ item }">
                        <b-link
                          class="alert-link"
                          :to="{
                            name: 'transactions-orders-preview',
                            params: { uuid: item.order_uuid },
                          }"
                        >
                          {{ item.order_id }}
                        </b-link>
                      </template>

                      <template #cell(classroom_from_name)="{ item }">
                        <b-link
                          class="alert-link"
                          :to="{
                            name: 'classrooms-edit',
                            params: { uuid: item.classroom_from_uuid },
                          }"
                        >
                          {{ item.classroom_from_name }}
                        </b-link>
                      </template>

                      <template #cell(classroom_to_name)="{ item }">
                        <b-link
                          class="alert-link"
                          :to="{
                            name: 'classrooms-edit',
                            params: { uuid: item.classroom_to_uuid },
                          }"
                        >
                          {{ item.classroom_to_name }}
                        </b-link>
                      </template>
                    </b-table>
                  </b-card>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <b-card v-if="course" header="Informações do curso" class="mb-1">
            <b-card-text>
              <div class="form-row">
                <div class="col-md-12">
                  <b-form-group>
                    <label for=""><strong>Título</strong></label>
                    {{ course.title }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="">
                      <strong
                        >Título - <small>Exibido no contrato</small></strong
                      >
                    </label>
                    {{ course.title_contract }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group>
                    <label for="">
                      <strong>Escolaridade</strong>
                    </label>
                    {{ course.scholarity.title }}
                  </b-form-group>
                </div>

                <div class="col-md-12">
                  <b-form-group class="mb-0">
                    <label for="">
                      <strong>Tipo</strong>
                    </label>
                    {{ course.type.title }}
                  </b-form-group>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-12">
                  <h4 class="mb-0">
                    <i class="text-danger bi bi-record-circle"></i>
                    Dias de aula
                  </h4>
                  <small>Habilite os dias de aula</small>
                </div>
              </div>
            </template>
            <b-card-text>
              <div class="d-block">
                <div
                  class="form-group"
                  v-for="(element, index) in optionsWorkDays"
                  :key="index"
                >
                  <b-form-checkbox
                    :checked="true"
                    :name="`day-${index}`"
                    switch
                    :id="`day-${index}`"
                    inline
                    v-model="item.weekdays"
                    :value="element.code"
                  >
                    {{ element.title }}
                  </b-form-checkbox>
                </div>
              </div>
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <h4 class="mb-0">ID da Grade Consys</h4>
              <small>Cadastre o <strong>ID da Grade </strong></small>
            </template>
            <b-card-text>
              <input
                type="text"
                class="form-control"
                v-model="item.consys_grid"
              />
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-9 col-8">
                  <h4 class="mb-0">Turma(s) no Consys</h4>
                  <small>Cadastre o <strong>ID da Turma</strong></small>
                </div>
                <div class="col-md-3 col-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgainClassrooms"
                    class="btn-block"
                  >
                    <i class="bi bi-plus mr-25"></i>
                  </b-button>
                </div>
              </div>
            </template>
            <b-card-text>
              <b-form
                class="repeater-form"
                @submit.prevent="repeateAgainClassrooms"
              >
                <div
                  v-for="(element, index) in item.classrooms"
                  class="form-group"
                  :class="item.classrooms.length === index + 1 ? 'mb-0' : ''"
                  :key="index"
                >
                  <div class="form-row">
                    <div class="col-md-9">
                      <input
                        type="text"
                        class="form-control"
                        v-model="element.code"
                        placeholder="ID da turma"
                      />
                    </div>

                    <div class="col-md-3">
                      <button
                        type="button"
                        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                        class="mt-0 btn btn-block btn-outline-danger"
                        block
                        @click="removeItemClassrooms(index)"
                        style="
                          line-height: 1.5;
                          padding: 5px !important;
                          font-size: 1.5rem !important;
                        "
                      >
                        <i class="bi bi-x"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </b-form>
            </b-card-text>
          </b-card>

          <b-card class="mb-1 card-header-custom-actions" header-tag="header">
            <template #header>
              <div class="form-row">
                <div class="col-md-8 col-8">
                  <h4 class="mb-0">
                    <i class="text-danger bi bi-record-circle"></i>
                    Previsão de Início
                  </h4>
                  <small>Cadastre 1 ou mais datas</small>
                </div>
                <div class="col-md-4 col-4">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgainPostponements"
                    class="btn-block"
                  >
                    <i class="bi bi-plus mr-25"></i>
                    <span>Novo</span>
                  </b-button>
                </div>
              </div>
            </template>
            <b-card-text>
              <p :class="item.postponements.length > 0 ? 'mb-4' : 'mb-0'">
                A última data cadastrada, será considerada a próxima data de
                início.
              </p>
              <b-form
                class="repeater-form"
                @submit.prevent="repeateAgainPostponements"
              >
                <app-timeline>
                  <div
                    v-for="(element, index) in item.postponements"
                    :id="`post-${index}`"
                    :key="index"
                  >
                    <app-timeline-item icon="calendar-event">
                      <div class="input-group">
                        <flat-pickr
                          :disabled="
                            index !== item.postponements.length - 1
                              ? true
                              : false
                          "
                          v-model="element.date"
                          class="form-control"
                          :class="
                            index !== item.postponements.length - 1
                              ? 'disabled'
                              : ''
                          "
                          :config="{
                            altInput: true,
                            dateFormat: 'Y-m-d',
                            altFormat: 'd/m/Y',
                            locale: 'pt',
                          }"
                        />
                        <div
                          v-if="index === item.postponements.length - 1"
                          class="input-group-append"
                        >
                          <b-button
                            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                            variant="outline-danger"
                            class="mt-0"
                            block
                            @click="removeItemPostponements(index)"
                            style="
                              line-height: 1.5;
                              padding: 5px !important;
                              font-size: 1.5rem !important;
                            "
                          >
                            <i class="bi bi-x"></i>
                          </b-button>
                        </div>
                      </div>
                    </app-timeline-item>
                  </div>
                </app-timeline>
              </b-form>
            </b-card-text>
          </b-card>

          <ButtonsActionsFooter
            routerBack="classrooms-list"
            variant="success"
            :submited="submited"
            text="Salvar"
            subtext="Aguarde..."
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BForm,
  BTable,
  BTabs,
  BTab,
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardFooter,
  BCardHeader,
  VBTooltip,
  BFormCheckbox,
  VBToggle,
  BButton,
  BModal,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BBadge,
  BLink,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { required, url, minLength, requiredIf } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

export default {
  components: {
    ButtonsActionsFooter,
    BSpinner,
    flatPickr,
    BLink,
    BModal,
    BFormCheckbox,
    AppTimeline,
    BBadge,
    BTabs,
    BTable,
    BTab,
    AppTimelineItem,
    BCard,
    BCardHeader,
    BCardText,
    BCardFooter,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
    VBTooltip,
  },
  data() {
    return {
      nextIdPostponements: 0,
      submited: false,
      submittedTransfer: false,
      selectAll: false,
      course: "",
      item: {
        uuid: "",
        name: "",
        link: "",
        modality: "",
        course: "",
        max_students: "",
        classrooms: [],
        consys_grid: "",
        date: "",
        start_time: "",
        end_time: "",
        interval: "00:15",
        display_panel: false,
        unit: "",
        status: { title: "Ativo", code: 1 },
        disciplines: [],
        postponements: [],
        weekdays: [],
        confirmed: false,
        customers: [],
        date_end: "",
      },
      formTransfer: {
        classroom: "",
        orders: [],
      },
      optionsModalities: [],
      optionsCourses: [],
      optionsUnits: [],
      optionsShifts: [],
      optionsWorkDays: [],
      selectedsDays: [],
      disciplines: [],
      optionsClassrooms: [],
      fieldsTransfers: [
        {
          key: "status",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 20px",
        },
        {
          key: "order_id",
          label: "Matrícula",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30px",
        },
        {
          key: "customer_name",
          label: "Aluno(a)",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "classroom_from_name",
          label: "Origem",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30px",
        },
        {
          key: "classroom_to_name",
          label: "Destino",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 30px",
        },
        {
          key: "user_name",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 20px",
        },
        {
          key: "created_at",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 180px",
        },
      ],
      fieldsCustomers: [
        {
          key: "select",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
          thStyle: "width: 20px",
          class: "no-print",
        },
        {
          key: "order_id",
          label: "ID",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "customer_name",
          label: "Nome completo",
          thClass: "",
          tdClass: "font-weight-bold",
        },
        {
          key: "customer_cpf",
          label: "CPF",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "customer_email",
          label: "E-mail",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "phone",
          label: "Telefone",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "order_date",
          label: "Data",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
        {
          key: "status_name",
          label: "Status",
          thClass: "text-center",
          tdClass: "text-center font-weight-bold",
        },
      ],
      payment_forms: [
        {
          code: "money",
          icon: "bi-cash-stack",
          title: "Dinheiro",
        },
        {
          code: "card_debit",
          icon: "bi-credit-card-2-front",
          title: "Débito",
        },
        {
          code: "card_credit",
          icon: "bi-credit-card-2-back",
          title: "Crédito",
        },
        {
          code: "pix",
          icon: "bi-qr-code-scan",
          title: "PIX",
        },
        {
          code: "check",
          icon: "bi-card-text",
          title: "Cheque",
        },
        {
          code: "letter",
          icon: "bi-file-earmark-medical",
          title: "Carta de Crédito",
        },
        {
          code: "billet",
          icon: "bi-upc-scan",
          title: "Boleto",
        },
      ],
      orders: [],
    };
  },
  validations: {
    formTransfer: {
      classroom: {
        required,
      },
      orders: {
        required,
      },
    },
    item: {
      name: {
        required,
      },
      course: {
        required,
      },
      date_end: {
        required,
      },
      modality: {
        required,
      },
      start_time: {
        required,
        minLength: minLength(5),
      },
      end_time: {
        required,
        minLength: minLength(5),
      },
      interval: {
        required,
        minLength: minLength(5),
      },
      status: {
        required,
      },
      postponements: {
        required,
      },
      unit: {
        required: requiredIf((vm) => {
          return vm.modality.title === "Presencial" ? true : false;
        }),
      },
      link: {
        url,
        required: requiredIf((vm) => {
          return vm.modality.title === "Live" ? true : false;
        }),
      },
      shift: {
        required,
      },
      weekdays: {
        required,
      },
    },
  },
  watch: {
    selectedsDays(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false;
      } else if (newValue.length === this.flavours.length) {
        this.indeterminate = false;
      } else {
        this.indeterminate = true;
      }
    },
  },
  methods: {
    firstName(customerName) {
      const fullName = customerName.split(" ");
      const firstName = fullName[0];
      const lastNameInitial = fullName[fullName.length - 1].charAt(0);

      return `${firstName} ${lastNameInitial}.`;
    },
    selectOrder(uuid) {
      const index = this.formTransfer.orders.indexOf(uuid);
      if (index !== -1) {
        this.formTransfer.orders.splice(index, 1);
      } else {
        this.formTransfer.orders.push(uuid);
      }
    },
    confirmTransfer() {
      this.$v.formTransfer.$touch();

      if (!this.$v.formTransfer.$error) {
        this.$swal({
          title: "Confirma transferência?",
          text: `Você está prestes a transferir as matrículas selecionadas para a turma ${this.formTransfer.classroom?.title}`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sim, transferir!",
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-light ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.submittedTransfer = true;

            this.$store
              .dispatch("Classroom/transfer", {
                uuid: this.item.uuid,
                data: this.formTransfer,
              })
              .then(() => {
                this.getData();
                this.notifyDefault("success");
                this.$swal({
                  icon: "success",
                  title: "Transferência feita!",
                  text: "Operação realizada com sucesso.",
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              })
              .finally(() => {
                this.submittedTransfer = false;
              });
          }
        });
      }
    },
    async fetchClassrooms(term) {
      this.optionsClassrooms = [];

      if (term && term.length > 2) {
        this.$store.dispatch("Classroom/search", term).then((res) => {
          this.optionsClassrooms = res;
        });
      }
    },
    toggleSelectAll() {
      if (this.selectAll) {
        this.item.customers.forEach((customer) => {
          customer.selected = 0;
          this.formTransfer.orders.push(customer.order_uuid);
        });
      } else {
        this.item.customers.forEach((customer) => {
          customer.selected = 1;
        });
        this.formTransfer.orders.splice(0, this.formTransfer.orders.length);
      }
    },
    async courseSelected(value) {
      const course = await this.$store.dispatch("Course/show", value.code);
      this.item.course = {
        title: course.title,
        code: course.uuid,
      };
      this.course = course;
      this.disciplines = course.disciplines;
    },
    async getData() {
      await this.$store
        .dispatch("Classroom/show", this.$route.params.uuid)
        .then((res) => {
          this.item = res;
          this.course = this.item.course;
          this.disciplines = this.course.disciplines;
          const course = {
            code: this.item.course.uuid,
            title: this.item.course.title,
          };

          delete this.item.course;

          this.item.course = course;

          this.item.customers.filter((customer) => {
            customer.selected = 0;
          });
        });
    },
    repeateAgainClassrooms() {
      this.nextIdClassrooms = this.nextIdClassrooms + 1;
      this.item.classrooms.push({
        id: this.nextIdClassrooms,
      });
    },
    removeItemClassrooms(index) {
      this.item.classrooms.splice(index, 1);
    },
    repeateAgainPostponements() {
      this.nextIdPostponements = this.nextIdPostponements + 1;
      this.item.postponements.push({
        id: this.nextIdPostponements,
      });
    },
    removeItemPostponements(index) {
      this.item.postponements.splice(index, 1);
    },
    async fetchCourse(term) {
      this.optionsCourses = [];
      if (term.length > 2) {
        this.optionsCourses = await this.$store.dispatch(
          "Course/fetchSearchStatus",
          { term, status: 1 }
        );
      }
    },
    printTable() {
      const printContent = this.$refs.printSection.innerHTML;
      const printWindow = window.open("", "", "height=800,width=1000");
      printWindow.document.write(
        "<html><head><title>Impressão da Tabela</title>"
      );
      printWindow.document.write(
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css">'
      );
      printWindow.document.write("</head><body>");
      printWindow.document.write(printContent);
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.focus();
      printWindow.print();
      printWindow.close();
    },
    submitUpdate() {
      this.$v.item.$touch();
      this.submited = true;

      if (!this.$v.item.$error) {
        this.$store
          .dispatch("Classroom/update", {
            uuid: this.item.uuid,
            data: this.item,
          })
          .then(() => {
            this.notifyDefault("success");
            this.$router.push({ name: "classrooms-list" });
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.submited = false;
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async mounted() {
    this.optionsModalities = await this.$store.dispatch(
      "Classroom/forSelectTypes"
    );
    this.optionsShifts = await this.$store.dispatch("Shift/forSelect");
    this.optionsUnits = await this.$store.dispatch("Unit/forSelect", 1);
    this.optionsWorkDays = await this.$store.dispatch("Classroom/weekdays");

    this.getData();

    this.fetchClassrooms();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
.area-lessons {
  min-height: 400px;
}

.list-disciplines {
  .d-flex-between-full:last-child {
    padding-bottom: 0px !important;
  }
}

.custom-control-orders {
  margin: 0px !important;
}
</style>
